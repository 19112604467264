<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner py-2 main-content">
      <b-card
        no-body
        class="custom-card"
      >
        <b-row>
          <b-col lg="6">
            <div class="main-login-left">
              <div class="d-flex justify-content-center w-full">
                <div class="w-full">
                  <b-img
                    src="../assets/images/logo/logo-mis-1.png"
                    height="70px"
                  />
                </div>
                <!--                <div class="name-system">-->
                <!--                  <h1 class="pl-1">-->
                <!--                    MISVN-->
                <!--                  </h1>-->
                <!--                </div>-->
              </div>
              <div class="form-login mt-2">
                <validation-observer ref="loginForm">
                  <b-form @submit.prevent="login">
                    <b-row>
                      <b-col cols="12">
                        <b-form-group>
                          <label for="userNameLogin">Tên tài khoản</label>
                          <validation-provider
                            #default="{ errors }"
                            name="userName"
                            rules="required"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid':null"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="UserIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="userNameLogin"
                                v-model="modelLogin.username"
                                class="form-control-merge"
                                placeholder="Nhập tên đăng nhập"
                                :state="errors.length > 0 ? false:null"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group>
                          <label for="passwordLogin">Mật khẩu</label>
                          <validation-provider
                            #default="{ errors }"
                            name="userPassword"
                            rules="required|min:6"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid':null"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="LockIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="passwordLogin"
                                v-model="modelLogin.password"
                                class="form-control-merge"
                                type="password"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Nhập mật khẩu"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <div class="check-remember-forget-password d-flex justify-content-between">
                          <b-form-group>
                            <b-form-checkbox
                              id="checkbox-1"
                              name="checkbox-1"
                              :checked="rememberMe"
                              @change="onChangeRememberMe"
                            >
                              Ghi nhớ đăng nhập
                            </b-form-checkbox>
                          </b-form-group>
                          <b-link>
                            <span>Quên mật khẩu</span>
                          </b-link>
                        </div>
                      </b-col>
                      <b-col cols="12">
                        <b-button
                          variant="primary"
                          style="width: 100%"
                          block
                          type="submit"
                        >Đăng nhập</b-button>
                      </b-col>
                      <!--                      <b-col cols="12">-->
                      <!--                        <div class="mt-2 text-center">-->
                      <!--                          <span>Bạn chưa có tài khoản?</span>-->
                      <!--                          <b-link href="#">-->
                      <!--                            <span>-->
                      <!--                              Đăng ký tài khoản-->
                      <!--                            </span>-->
                      <!--                          </b-link>-->
                      <!--                        </div>-->
                      <!--                      </b-col>-->
                      <b-col cols="12">
                        <div class="text-center mt-1">
                          <b-link href="/tuyen-sinh">
                            <span>
                              Thông tin tuyển sinh
                            </span>
                          </b-link>
                        </div>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="image_login">
              <b-img
                src="../assets/images/logo/anh-login-custom.jpg"
              />
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BInputGroupPrepend,
  BFormCheckbox,
  BLink,
  BButton,
  BImg,
} from 'bootstrap-vue'
import { required, min } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
// import useJwt from '@/auth/jwt/useJwt'
// import axios from 'axios'
// import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BLink,
    BButton,
    BImg,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    // const listDataFake = ref([])
  },
  data() {
    return {
      rememberUserStr: '',
      rememberMe: false,
      status: '',
      modelLogin: {
        username: '',
        password: '',
      },
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      min,
      listFake: [],
    }
  },
  created() {
    this.rememberUserStr = localStorage.getItem('rememberUser')
    if (this.rememberUserStr) {
      const rememberUser = JSON.parse(this.rememberUserStr)
      this.rememberMe = true
      this.modelLogin.username = rememberUser.userName
      this.modelLogin.password = rememberUser.password
    }
  },
  methods: {
    login() {
      const formData = new FormData()
      localStorage.removeItem('rememberUser')
      localStorage.removeItem('access_token')
      if (this.rememberMe) {
        localStorage.setItem('rememberUser', JSON.stringify({
          userName: this.modelLogin.username,
          password: this.modelLogin.password,
        }))
      }
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          formData.append('grant_type', 'password')
          formData.append('scope', 'read')
          formData.append('username', this.modelLogin.username)
          formData.append('password', this.modelLogin.password)
          this.$authUser.post('/oauth/token', formData, {
            headers: {
              Authorization: 'Basic Y2xpZW50SWQ6c2VjcmV0',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
            .then(res => {
              localStorage.setItem('userData', JSON.stringify(res.data))
              localStorage.setItem('access_token', res.data.access_token)
              this.$crm.post(`/user/find-by-userName/${this.modelLogin.username}`).then(resp => {
                localStorage.setItem('dataMenuUser', JSON.stringify(resp.data.userManagementRequestDTOList))
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Thành công!',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: 'Bạn đã đăng nhập thành công',
                  },
                })
                this.$router.push('/home')
              })
            })
          // useJwt.setToken(response.data.accessToken)
        }
      })
    },
    onChangeRememberMe(e) {
      this.rememberMe = e
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/pages/page-auth.scss';
@import 'src/@core/scss/custom/login';

.image_login {
  width: 100%;
  img {
    max-width: 100%;
    border-radius: 0 8px 8px 0;
  }
}
</style>
